.games_section {
  /* background-color: white !important; */
  /* background: #fff;  */
  border-radius: 0;
  position: relative;
  width: 100%;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  /* background-color: rgb(255, 255, 255);  */
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.games_section_title {
  color: rgb(44, 44, 44);
  font-size: 1em;
  font-weight: 700;
}
.MuiFormControl_root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0px;
  flex-direction: column;
  vertical-align: top;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-top-style: initial;
  border-right-style: initial;
  border-bottom-style: initial;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
.gameCard_container {
  margin-top: 20px;
  justify-content: center;
  position: relative;
  width: 46.8%;
}

.games_window {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.add_fund_box {
  background: #fafafa;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 30px 0 15px 15px;
  position: relative;
  width: 100%;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: rgb(250, 250, 250);
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: rgb(224, 224, 224);
  border-right-color: rgb(224, 224, 224);
  border-bottom-color: rgb(224, 224, 224);
  border-left-color: rgb(224, 224, 224);
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-top: 30px;
  padding-right: 0px;
  padding-bottom: 15px;
  padding-left: 15px;
}
.collapseCard_title {
  color: rgb(103, 103, 103);
  font-size: 0.7em;
  font-weight: 700;
  text-transform: uppercase;
}
.refer_footer {
  align-items: center;
  background-color: rgb(250, 250, 250);
  border-top: 1px solid #e0e0e0;
  bottom: 0px;
  display: flex;
  height: 73px;
  left: 0px;
  max-width: 480px;
  padding: 0 30px;
  /* position: fixed;  */
  width: 100%;
  z-index: 3;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgb(224, 224, 224);
  padding-top: 0px;
  padding-right: 30px;
  padding-bottom: 0px;
  padding-left: 30px;
}
.refer_button {
  border: none;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 700;
  height: 48px;
  padding: 0 22px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  border-top-width: initial;
  border-right-width: initial;
  border-bottom-width: initial;
  border-left-width: initial;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-color: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-top: 0px;
  padding-right: 22px;
  padding-bottom: 0px;
  padding-left: 22px;
}

.cxy {
  align-items: center;
  display: flex;
  justify-content: center;
}

.bg_secondary {
  background-color: rgb(108, 117, 125) !important;
}
.btn:hover {
  background-color: #fff !important;
}
.block {
  display: block;
  width: 100%;
  border: none;
  background-color: #fafafa;
  padding: 14px 28px;
  font-size: 16px;
  text-align: center;
}
