
.invoice{
    /* border: 2px solid #fff; */
    width: 100%;
}
td , th{
    height: 35px;
}
table h1 , p {
    margin-bottom: 0;
    padding-bottom: 0;
}
address{
    margin-bottom: 0;
}
  .table-1-head{
    padding: 0px 40px ;
  }
  .font_sm{
    font-size: 11px;
    font-weight: 100;
  }
  .company_name{
    font-size: 25px;
    font-weight: 700;
  }
  .date{
    font-size: 20px;
    font-weight: 600;
  }
  .detail{
    font-size: 18px;
    font-weight: 500;
  }
  .detail2{
    font-size: 17px;
    font-weight: 500;
  }
  .paytment{
    width: 80%;
    margin: auto;
  }
  .table_1{
    padding: 0px 10px;
    position: relative;
    top: -25px;
  }
  .table_2_tr td{
    padding-bottom: 10px;
  }
  .width_50{
    width: 50%;
  }
  .width_20{
    width: 20%;
  }
  .width_25{
    width: 25%;
  }
  .width_30{
    width: 30%;
  }
  .width_5{
    width: 5%;
  }
  .width_45{
    width: 45%;
  }
  .width_55{
    width: 55%;
  }
  .bottom_table{
    display: flex;
    width: 100%;
  }
  .hei_30{
    max-height: 70px;
    height: 70px;
  }
  .same_height{
    width: 100%;
    height: 231px;
    max-height: 231px;
  }
  .pd_left{
    padding-left: 10px;
  }
  .invoice table , th ,td , tr{
    border-top:  2px solid #fff;
    border-left: 2px solid #fff ;
    border-right:  2px solid #fff;
    border-bottom:  2px solid #fff;
    border-collapse: collapse;
  }

  .w_100{
    width: 100%;
  }
  .op{
    opacity: 0;
  }
  .top_table{
    display: flex;
    justify-content: space-between;
  }
  .text_center{
    text-align: center;
  }
  .onediv{
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
  }
   .la table ,th ,td ,tr{
    border-right: 0px   !important;
    border-top: 0px;
  }
  .la2 table ,th ,td ,tr{
    border-top: 0px;
  }
  

  
 
 