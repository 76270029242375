.main-area {
  background-color: transparent;
  overflow: hidden;
  width: 100%;
}

.splash-overlay {
  /* background: linear-gradient(rgba(0, 0, 0, 0.61),rgba(0, 0, 0, 0.596)); */
  /* background: linear-gradient(180deg, transparent -500px, #000 600.5px); */
  background-size: cover !important;
  background-position: center !important;

  /* height: 100vh; */
  pointer-events: none;
  position: absolute;
  width: 100%;
  max-width: 480px;
  z-index: 2;
}
/*.w3-sidebar > a > div{*/
/*    font-family: "Luckiest Guy", cursive;*/
/*    font-size: small;*/
/*    letter-spacing: 2px;*/
/*}*/
.splash-screen {
  -webkit-animation: splashAnim 22s linear infinite;
  animation: splashAnim 22s linear infinite;
  height: 100%;
}

.center-xy {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.login-footer {
  padding: 0 10px;
  /* padding-top: 120px; */
  bottom: 0%;
  color: #676767;
  /* font-size: 0.75em;
  font-weight: 400;
  line-height: 15px; */
  /* position: sticky;
  text-align: center; */
  /* max-width: 480px;
  width: 100%; */
  z-index: 2;
  font-size: 0.7rem;
  text-align: left;
  /* line-height: 22px; */
  color: #003441;
  font-family: 'Poppins', sans-serif;
  margin: 0 20px;
  position: absolute;
  bottom: 5%;
}

element.style {
  top: 45%;
  z-index: 3;
}

.splash-screen {
  -webkit-animation: splashAnim 22s linear infinite;
  animation: splashAnim 22s linear infinite;
  height: 100%;
}

figure {
  margin: 0 0 1rem;
}

.font-15 {
  color: #2c2c2c;
  font-size: 1.5em;
  font-weight: 700;
}

.text-white {
  color: #fff !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}

element.style {
  width: 85%;
  height: 70px;
  border-radius: 5px;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

element.style {
  width: 100px;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.hidden {
  display: none !important;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}

.refer-button {
  border: none;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 700;
  height: 48px;
  padding: 0 22px;
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
}

.refer-button:hover {
  color: white !important;
  text-decoration: none;
}

.bg-green {
  background-color: #0db25b;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
input[name='mobile'] {
  border: 1px solid #d8d6de;
}
input[name='mobile']:focus {
  border-color: #7367f0;
}

.Login-button {
  border: none;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
  height: 48px;
  padding: 0 22px;
  color: #fff;
  /* border: 3px solid #f4bc41; */
  /* box-shadow: rgb(244 188 65) 0px 0px 1rem; */
  /* font-family: "Luckiest Guy", cursive !important; */
  /* background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)); */
  background-color: #000;
  width: 85%;
  font-weight: 900;
  /* letter-spacing: 1px; */
  text-transform: capitalize;
  letter-spacing: 1px;
}
.login_box_main_box {
  border: 1px solid #828282;
  width: 90%;
  padding: 40px 10px;
  border-radius: 5px;
  background-color: #ffcc00;
}
.login_input_field_main {
  width: 85%;
}
.form_haeding {
  font-size: 2rem;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #003441;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 50px;
}

.login_input_field_inner {
  position: relative;
  margin-bottom: 30px;
}
.otp_filed .login_input_field_inner {
  margin-bottom: 10px;
}
.resend_otp_div {
  margin-bottom: 20px;
  display: flex;
  align-items: end;
  width: 85%;
  justify-content: end;
}
.resend_otp_div h4 {
  font-size: 1rem !important;
  font-weight: 600;
  color: #003441;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
}
.resend_otp_div h4 span {
  color: #ee2222;
}
.login_input_field_inner span {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 1rem;
  font-weight: 600;
  color: #003441;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
}

.login_input_field_inner .form_control {
  width: 100%;
  padding: 15px 15px 15px 50px;
  border: 1px solid rgb(110, 110, 110);
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  color: #003441;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
}
.login_input_field_inner .form_control::placeholder {
  font-size: 1rem;
  font-weight: 400;
  color: #646464;
}
.otp_expire {
  font-size: 12px !important;
  font-weight: 500;
  color: #b70000;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
}

.otp_expire button {
  font-size: 12px !important;
  font-weight: 600;
  color: #003441;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  border: none;
  background: transparent;
}
