.leftContainer{
    min-height: 100%;
    position: relative;
}
.headerContainer, .leftContainer {
    background-color: #f9f9f9;
    max-width: 480px;
    width: 100%;
   /* height: 100vh; */
}
.rightContainer {
    /* background: url(/images/global-bg-rays.svg); */
    background-color: #fff;
    background-size: cover;
    overflow: hidden;
    right: 0;
    z-index: 4;
}
.close-button {
    margin-left: 35% !important;
    font-size: 18px !important;
    color: white !important;
    background-color: red !important;
  }
.rightContainer {
    bottom: 0;
    left: 480px;
    position: fixed;
    top: 0;
    border-left: 10px solid #e0e0e0;
}
#root{
    height: 100vh;
}
@media screen and (max-width: 720px) {
    .auto_youtbe{
        font-size: 9px !important;
        padding: 0px 9px !important;
        color: #fff !important;
      
    }
}
.modeButton{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .custom-modal {
    border-radius: 12px;
    overflow: hidden;
  }
  .modal-header-custom {
    background-color: #4a90e2;
    color: white;
    text-align: center;
    font-size: 18px;
    padding: 15px;
  }
  .modal-body-custom {
    text-align: center;
    padding: 30px 20px;
  }
  .qr-container {
    margin: 20px auto;
    width: fit-content;
  }
  .button-group {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 30px;
  }
  .pay-button {
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    flex: 1;
    max-width: 180px;
    text-align: center;
  }
  .phonepe {
    background-color: #6a1b9a;
  }
  .paytm {
    background-color: #0033cc;
  }
  .gpay {
    background-color: #34a853;
  }
  .pay-button:hover {
    opacity: 0.9;
  }
  .close-button-container {
    display: flex;
    /* justify-content: center; */
    margin-top: 20px;
  }
  .close-button {
    padding: 12px 40px;
    background-color: #d32f2f;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  .close-button:hover {
    background-color: #b71c1c;
  }