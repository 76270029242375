.headerContainer,
.leftContainer {
  background-color: #fff;
  max-width: 480px;
  width: 100%;
}



* {
  font-family: 'Roboto';
}

*,
:after,
:before {
  box-sizing: border-box;
}

.leftContainer {
  min-height: 0%;
  position: relative;
}

.headerContainer {
  position: fixed;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 6%);
  height: 0px;
  padding: 0;
  top: 0;
  z-index: 2;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.navLogo > img {
  height: 36px;
  width: 36px;
}

.login-btn {
  border: 1px solid #003441;
  color: #003441 !important;
  border-radius: 3px;
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 1px;
  /* height: 30px; */
  padding: 6px 16px;
  margin-right: 10px;
  background-color: #ffcc00;
  font-family: 'Poppins', sans-serif;
}
.login-btn:hover {
  background-color: #003441;
  color: #fff !important;
}

.rightContainer {
  /* background: url('https://annexlogics.com/static/media/08.17355bf8.webp'); */
  /* background: linear-gradient(to top, #d1c952ba, #d1c952ba),
    url('https://e1.pxfuel.com/desktop-wallpaper/965/285/desktop-wallpaper-file-ludo-ludo-game-board.jpg')
      no-repeat top center; */
  background-color: #fff;
  background-size: cover;
  overflow: hidden;
  right: 0;
  z-index: 4;
}

.rightContainer {
  bottom: 0;
  background-color: #000;
  color: #fff;
  left: 480px;
  position: fixed;
  top: 0;
  border-left: 10px solid #e0e0e0;
}

.rcBanner-footer > img {
  height: 25px;
  width: 25px;
  margin-top: 4px;
}

.rcBanner-img-container {
  bottom: 45%;
  position: absolute;
  width: 300px;
}

.rcBanner-img-container > img {
  height: auto;
  width: 300px;
}

.flex-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rcBanner-text {
  bottom: 35%;
  font-family: 'Roboto Condensed';
  font-size: 2em;
  font-weight: 400;
  position: absolute;
  text-transform: uppercase;
}

.rcBanner-text-bold {
  font-weight: 900;
}

.main-area {
  background-color: transparent;
  overflow: hidden;
  width: 100%;
}

.games-section {
  background: #fff;
  border-radius: 0;
  position: relative;
  width: 100%;
}

.p-3 {
  padding: 1rem !important;
}

.games-section-headline {
  color: #959595;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 18px;
}

.games-window {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.gameCard-container {
  /* margin-top: 20px; */
  justify-content: center;
  position: relative;
  width: 46.8%;
}

.blink {
  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

.gameCard {
  background-color: #fff;
  border-radius: 5px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}

.gameCard-image {
  border-radius: 5px;
  height: 68.33%;
  width: 100%;
}
.gameCard-image img {
  border-radius: 5px;
}
.gameCard-icon,
.gameCard-title {
  display: flex;
  justify-content: center;
}

.gameCard-title {
  border-radius: 0 0 5px 5px;
  border: 1px solid #e0e0e0;
  border-top: none;
  color: #2c2c2c;
  font-weight: 700;
  padding: 15px 20px 10px;
}

.games-section-title {
  color: #3e7966;
  font-size: 1.2em;
  font-weight: 600;
  padding: 10px;
  /* background-color: #3e7966; */
  display: block;
  justify-content: center;
  align-items: center;
  /* border-radius: 4px; */
  border-bottom: 1px solid;
  text-align: center;
}

.rcBanner-footer {
  bottom: 40px;
  color: #fff;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  text-align: center;
}

.footer {
  /* background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important; */
  border-radius: 0 !important;
  position: relative !important;
  width: 100% !important;

  padding: 1% !important;
}

.footer-divider {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.footer-links > a {
  color: #959595 !important;
  display: flex !important;
  font-size: 1.1em !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
}

.footer-text-bold {
  color: #959595;
  font-size: 1em;
  font-weight: 700;
}

.footer-text {
  color: #959595;
  font-size: 0.9em;
  font-weight: 400;
}

a:hover {
  color: #170fa2 !important;
  text-decoration: underline;
}

a {
  color: #170fa2 !important;
  text-decoration: none;
  background-color: transparent;
}

.gameCard-container .goverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.collapseCard-container {
  padding: 30px 20px 20px;
  background-color: #fafafa;
}
.collapseCard {
  position: relative;
  width: 100%;
}
.collapseCard-body {
  padding-left: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  align-items: center;
  display: flex;
}

.collapseCard-header {
  background-color: #fafafa;
  padding-left: 3px;
  padding-right: 3px;
  top: -13px;
  align-items: center;
  position: absolute;
  display: flex;
  justify-content: center;
}
.collapseCard-title {
  color: #676767;
  font-size: 0.7em;
  font-weight: 700;
  text-transform: uppercase;
}

.profile_info_small_box_main_row {

  padding: 24px 15px;
  background-color: #fff;
}
.profile_info_small_box_main {
  background-image: url('/public/bkgImage.jpg');
  background-size: cover; /* Ensures the image covers the entire background */
  background-position: bottom; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  padding: 0.6rem;
  border: 1px solid #c8c8c8;
  border-radius: 0.375rem;
  min-height: 140px;
  /* margin-bottom: 20px; */
}

.profile_info_small_box_main h3 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.profile_info_small_box_main h4 {
  font-size: 18px;
  font-weight: 600;
  color: #c3f414;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  margin-bottom: 0px;
}
.profile_info_small_box_inner {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
}
/* .bg_1 {
  background-color: rgb(244, 195, 195);
}
.bg_2 {
  background-color: rgb(221 195 244);
}
.bg_3 {
  background-color: rgb(186, 250, 173);
} */
.profile_info_small_box_inner img {
  max-width: 30px;
}

.profile_div_main_box {
  /* background-color: #f6f8fa; */
  background-image: url('/public/bkgImage.jpg');
  background-size: cover; /* Ensures the image covers the entire background */
  background-position: bottom; /* Centers the image */
  background-repeat: no-repeat;
  margin-top: 70px;
  padding: 24px 15px;
  border: 1px solid rgba(30, 41, 59, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 46px;
}
.profile_wallet {
  background-color: #7b1680 !important;
  color: #fff !important;
  font-size: 17px;
  padding: 6px 16px;
  font-family: 'Poppins', sans-serif;
  box-shadow: none !important;
  border: none !important;
  outline: none;
  border-radius: 5px !important;
}
.profile_wallet:hover {
  color: #fff !important;
}

.complete_kyc_btn {
  background-color: #7b1680 !important;
  color: #fff !important;
  font-size: 18px;
  padding: 6px 16px;
  font-family: 'Poppins', sans-serif;
  width: fit-content;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
}
.complete_kyc_btn:hover {
  color: #fff !important;
}
.Profile_mytext__v9ly5 {
  color: #fff !important;
}
.complete_kyc_btn img {
  max-width: 30px;
  margin-right: 10px;
}
.color-theme{
  font-size: 16px !important;
    font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.5px;
  color: #7b1680;
}
.profile_div_main_inner {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.profile_div_main_inner .profie_img {
  border-radius: 50%;
  max-width: 80px;
  height: 80px;
  width: 80px;
  margin-right: 20px;
}
.profile_details h2 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}
.profile_details h3 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  margin-top: 8px;
}

.Complete_Profile_box {
  padding: 25px 15px;
  background-color: #fff;
}
.border_top {
  border: 1px solid #e0e0e0;
}
.profile_headings {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: #000000;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.commaon_input_box {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: #003441;
  letter-spacing: 0.5px;
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid rgb(189, 189, 189) !important;
}
.commaon_label {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #003441;
}
.profile_name_edit {
  margin-left: 30px;
  cursor: pointer;
}
.profile_edit_input {
  padding: 5px;
  background-color: transparent;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3d3d3d;

  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.user_reder_code_box {
  display: flex;
  position: relative;
}
.user_reder_code_box .check_btn {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 7px;
}
.logout_div {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.logout_div a {
  background-color: #ffcc00;
  margin: 0 15px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #000 !important;
  padding: 12px 24px;
  width: fit-content;
}
.logout_div button {
  background-color: #fe3347;
  margin: 0 15px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #fff !important;
  padding: 12px 24px;
  width: fit-content;
  border: none;
}
.logout_div a:hover {
  color: #fff !important;
}
.profile_history_btns_div {
  border: 1px solid #e0e0e0;
}
.profile_history_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile_history_btns a {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #060606 !important;
  background-color: #f6f8fa;
  padding: 0.6rem 2rem;
  border: 1px solid #c8c8c8;
  border-radius: 20px;

  text-align: center;
}
.leftContainer {
  /* background-color: #fff; */
}
.Orher_page_main_section {
  margin-top: 20px;
  padding: 15px;
}
.Orher_page_main_section .profile_headings {
  text-align: left;
  margin-bottom: 20px;
}
.Notifications_btns_div {
  display: flex;
  justify-content: space-between;
}
.Orher_page_main_section .nav {
  flex-wrap: nowrap;
  overflow: auto;
}
.Orher_page_main_section .nav-tabs .nav-link {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #060606 !important;
  background-color: #f6f8fa;
  padding: 0.6rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 20px !important;
  text-transform: capitalize;
}
.Orher_page_main_section .nav-tabs {
  display: flex;
  justify-content: space-between;
  border: none;
}
.Orher_page_main_section .nav-tabs .nav-link.active {
  background-color: #fe3347;
  color: #fff !important;
}
.other_pages_card_main {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  background-color: #f6f8fa;
  padding: 0.6rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
  margin-top: 20px;
  /* background-color: #000; */
}

.other_pages_card_main h3 {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #060606 !important;
}

.other_pages_card_main p {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #414141;
  margin-bottom: 0 !important;
}
.dander_text {
  color: rgb(246 81 81) !important;
}
.dark_light_color {
  color: #5a5a5a;
}
.card_rupee {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #414141;
}
.card_date p {
  font-size: 12px !important;
}
.transaction_msg {
  font-size: 12px !important;
}
.transaction_right_side_main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.mb_space {
  padding-bottom: 80vh;
  padding-top: 30px;
  overflow-x: clip;
  height: inherit;
}

/* =========Add cash page=============== */

.add_amount_main_box_inner .label {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #000000;
}
.enter_amount_input_box {
  position: relative;
}
.enter_amount_input_box span {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #414141 !important;
}
.add_amount_main_box_inner input {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #414141 !important;
  width: 100%;
  padding: 8px 0 8px 35px;
  border: 1px solid #8a8a8a;
  border-radius: 5px;
}
.add_amount_main_box_inner input:focus {
  border: 1px solid #242424;
}
.add_amount_min_max {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.add_amount_min_max p {
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #f86262 !important;
}
.img-size{
  width: 22px;
  height: 27px;
  text-align: center;
  
}

.add_amount_buttons_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.add_amount_buttons_main button {
  border: none;
  background-color: #170fa2;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
}
.add_amount_buttons_main button:focus {
  background-color: #fe3347;
}

.add_cash_nest_btn {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_cash_nest_btn button {
  background-color: #7b1680;
  width: 80%;
  margin: auto !important;
  border: none;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  letter-spacing: 0.5px;
}

.add_cash_nest_btn button .icons {
  color: #fff !important;
  font-size: 24px;
  margin-left: 10px;
}
.deposit_service {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  letter-spacing: 0.5px;
}

.add_cash_small_haedings {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
}

.samll_btn {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
  border: none;
  box-shadow: none;
  background-color: #170fa2;
  color: #fff !important;
  border-radius: 5px;
  padding: 6px 16px;
  cursor: pointer;
}
.samll_btn_game{
  background-color: #2c41cf;
}
.samll_btn .icons {
  margin-right: 5px;
  font-size: 22px;
}
.check_btn{
  background-color: #000;

}
.normal_btn_text {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
}
.samll_card_btn_type {
  background-color: #f6f8fa;
  padding: 0.6rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
}
.support_samll_card_btn_type {
  background-color: #f6f8fa;
  padding: 0.2rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.support_samll_card_btn_type a,
.support_samll_card_btn_type span {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  letter-spacing: 0.5px;
  color: #000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.select_box {
  margin-top: 30px !important;
  width: 100%;
  background-color: #f6f8fa;
  padding: 0.6rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
}

.select_box option {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
}

.company_address {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  letter-spacing: 0.5px;
  color: #000 !important;
  margin-top: 30px !important;
}

.support_img_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.support_img_box img {
  max-width: 50%;
}

/* ===============refer page=============== */
.refer_page_main_section {
  padding: 20px;
  padding-top: 80px;
}
.refer_page_top_main_box_outer {
  border-radius: 8px;
  border: 1px solid #c8c8c8;
}
.refer_page_top_main_box h2 {
  /* background-color: #ffcc00; */
  padding: 10px;
  text-align: center;
  /* font-size: 18px !important;
  font-weight: 600 !important;
  font-family: var(--FF) !important;
  color: #222222 !important; */
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
color: #fff;
  background-color: #000;
  padding: 0.6rem;
  /* border: 1px solid #c8c8c8; */
}

.refer_page_top_main_box_inner {
  display: flex;
  padding: 10px;
}
.refer_page_top_main_box_inner_single {
  width: 50%;
  text-align: center;
}
.refer_page_top_main_box_inner_single h3 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.bo_le {
  border-right: 1px solid rgba(246, 7, 7, 0.5);
}
.Refer_code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.Refer_code h3 {
  width: 80%;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid rgb(0 0 0 / 50%);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #000000;
  letter-spacing: 1px;
  text-transform: capitalize;
  padding-left: 5px;
}
.Refer_code button {
  width: 20%;
  height: 40px;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: #fe3347;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-top: -7px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #fff !important;
}

.or_div h2 {
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 17px;
  letter-spacing: 1px;
}

.whatsapp_share_btn {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;
}
.whatsapp_share_btn a {
  background-color: #198754;
  color: #fff !important;
  padding: 10px;
  border-radius: 8px;
  width: 90%;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  max-width: 50px;
}
.whatsapp_share_btn a:hover {
  background-color: #000000;
  color: #fff !important;
}

.refer_rules p {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}

.main_heading_with_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main_heading_with_btn .profile_headings {
  margin-bottom: 0;
}
.main_heading_with_btn .profile_wallet {
  border-radius: 5px;
}
.main_heading_with_btn .profile_wallet .icons {
  font-size: 20px;
  margin-right: 6px;
}

.wallet_page_cards {
  background-image: linear-gradient(to left, #d41459, #911a6c);
  padding: 1.5rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
  margin-bottom: 20px;
}
.kyc_page_cards {
  background-color: #7b1680;
  padding: 1.5rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
  margin-bottom: 20px;
}
.kyc_page_cards p {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  color: #fff;
  margin-top: 10px;
}
.block_end_text {
  display: flex;
  align-items: center;
  justify-content: end;
}
.wallet_page_cards p {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  color: #fff;
}

.small_para {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  /* letter-spacing: 0.5px; */
  color: #666666;
  text-transform: capitalize;
}
table tr td {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  /* letter-spacing: 0.5px; */
  color: #666666;
  text-transform: capitalize;
}
.small_para a {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  color: #666666;
}
.other_page_small_headings {
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #222222;
}

/* battle page */

.Challenges_main_section {
  background-color: #f5f5f5;
  margin-bottom: 20px;
}

.Challenges_main_section h2 {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  font-family: 'Poppins', sans-serif !important;
  text-align: left;
  margin-bottom: 30px;
  margin-top: 80px;
}

.Challenges_single_box_main {
  text-align: left;
  border: 1px solid #454545;
  border-top: 0;
  border-radius: 5px;
  margin-bottom: 20px;
}
.Challenges_single_box_main .top__heading {
  padding: 5px 10px;
  background-color: #fe3347 !important;
  margin-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.Challenges_single_box_main h3 span {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif !important;
  color: #fff;
  line-height: 25px;
}
.Challenges_single_box_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-top: 1px solid #3e3e3e; */
  padding: 15px;
}

.Challenges_single_box_img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Challenges_single_box_img img {
  max-width: 35px !important;
  margin-right: 15px;
}

.Challenges_single_box_img h4 {
  font-size: 18px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif !important;
  color: #0e0e0e;
  margin-bottom: 0;
}
.running_bet_card {
  margin-top: 10px;
}
.running_bet_card .Challenges_single_box_main {
  background-color: whitesmoke !important;
  background-color: #f6f8fa;
  padding: 0.6rem;
  border: 1px solid #c8c8c8;
  border-radius: 0.375rem;
  position: relative;
}
.bet_vs_img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 35px !important;
}
.running_bet_card .Challenges_single_box_main .top__heading {
  background-color: transparent !important;
}
.running_bet_card .Challenges_single_box_main h3 span {
  color: #003441;
}
.running_bet_card .Challenges_single_box_img h4 {
  font-size: 20px !important;
}
.running_bet_card .Challenges_single_box_inner {
  padding: 5px 15px;
  /* border-top: 1px solid rgba(154, 154, 154, 0.586); */
}

.bg_green {
  /* background-color: rgb(72, 255, 72); */
  width: fit-content;
  float: right;
  padding: 2px 3px 0 3px;
  border-radius: 20px;
  margin-bottom: 10px;
  font-size: 10px;
}

.profile_info_small_box_main_row .rows {
  margin: 0px !important;
}

.battle_card_main {
  background-color: #f6f8fa;
  padding: 0.6rem 0rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
.card_bottom_border {
  border: 1px solid #00209d !important;
}
.battle_card_main .headings {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  /* color: #060606 !important; */
  padding-left: 0.6rem !important;
}
.card_icons {
  font-size: 26px;
  color: #fff;
}
.delete_buttons {
  background-color: #ffcc00;
  border: none;
  box-shadow: none;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px !important;
}
.battle_card_inner_bottom {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0.6rem !important;
  align-items: center;
}

.header_top_message {
  position: absolute;
  top: 0;
  z-index: 9999;
  background-color: #7b1680;
  width: 480px;
  text-align: center;
  padding: 8px;

  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #fff !important;

  max-width: 480px;
  width: 100%;
  position: fixed;
}

.game_live_status_position {
  position: absolute;
  top: 2px;
  left: 5px;
}

.whatsapp_share_btn .icons {
  font-size: 24px;
}
.whatsapp_share_btn a:nth-child(2) {
  background-color: rgb(229, 69, 69);
}
.whatsapp_share_btn a:nth-child(3) {
  background-color: #6e6eda;
}

.transaction_details_modal_body h1 {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #000000 !important;
  text-align: center;
  margin-bottom: 40px;
}

.transaction_details_modal_body .d_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.transaction_details_modal_body .d_flex h2 {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #000000 !important;
}

.transaction_Success {
  border-radius: 20px;
  padding: 5px;
}
.T_success {
  background-color: rgb(47, 248, 47);
}
.T_pending {
  background-color: rgb(248, 47, 47);
}
.T_process {
  background-color: rgb(31, 31, 251);
  color: #fff !important;
}

.modal-dialog {
  max-width: 478px !important;
}
.transaction_main_section_modal .modal-dialog {
  margin-left: 0px !important;
}

.eye_button {
  border: none;
  box-shadow: none;
  background-color: green;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.eye_button .icons {
  color: #fff;
  font-size: 22px;
}
.transaction_footer {
  border-top: 1px solid #3f3b3b;
  padding-top: 10px;
}
.transaction_footer p {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.7rem !important;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #f93131 !important;
  margin-bottom: 0 !important;
}
.battle_plat_btn {
  position: relative !important;
}

.request_btn {
  background-color: #ffcc00  !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
  text-transform: capitalize;
}
.reject_btn {
  background-color: #f93131 !important;
  color: #fff;
  text-transform: capitalize;

  border: none;
  box-shadow: none;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
}
.bet_player_name {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
  text-transform: capitalize;
}

.Home_betCard_playerName__L\+i1O {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
  text-transform: capitalize;
}

.download_btn_bottom {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
}
.commaon_para {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
  text-transform: capitalize;
}

.game_result_submit_model .cancel_popup_reason_popup {
  padding-right: 0 !important;
}

.swal2-popup {
  background-color: rgb(44, 65, 207) !important;
}
.swal2-title,
.swal2-html-container {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1.5rem !important;
  text-transform: capitalize !important;
  color: #fff !important;
}

.cancel_popup_reason_popup {
  padding-right: 0 !important;
}
.cancel_popup_reason_popup .modal-header {
  height: 0;
}
.win_modal .modal-content {
  margin-top: 0px;
}
.screenshot_img {
  max-width: 100%;
  max-height: 274px;
  object-fit: contain;
}

.profile_page .Orher_page_main_section .nav-tabs {
  justify-content: space-around !important;
  background-color: red !important;
}
.modal-content {
  padding: 15px;
}
.play_btn_color {
  color: #fff !important;
  background-color: #2c41cf !important;
}
.start_btn_color {
  color: #fff !important;
  background-color: #2c41cf !important;
}

.battle_divider {
  background: #fe3347;
  width: 100%;
  height: 3px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.games_Section_Title {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #28a745;
}
.game_section{
  color: #000;
}

.bank_page_cards {
  background-color: #fff;
  border: 1px solid #17d773;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.bank_page_cards h3 {
  color: #17d773;
  font-size: 20px;
  font-weight: 500;
}
.bank_page_cards ul li {
  color: #2b2b2b;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.modeButton{
  display: flex;
  align-items: center;
  justify-content: flex-start;
 
}
.small_btn{
 font-weight: 700;
}
/* .small_btn.active {
  background-color: #156fd6; 
  color: #fff !important; 
  padding: 10px !important;
  border-radius: 5px;
} */

.game_tabs_btn{
  background-color: #ffcc00 !important; 
  color: #fff !important; 
  padding: 12px 12px  !important;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;

}
.game_tabs_btn:hover{
  color: #fff !important; 

}
.game_tabs_btn.active {
  background-color: #fc424a !important; 
  color: #fff !important; 
}
@media screen and (max-width: 480px) {
  .running_bet_card .Challenges_single_box_main {
    padding: 0;
  }
  .profile_info_small_box_main {
    padding: 5px;
    min-height: 100px;
  }
  .Complete_Profile_box {
    padding: 10px 0px;
    margin: 5px;
  }
  .Complete_Profile_box .complete_kyc_btn {
    width: 97%;
  }
  .profile_info_small_box_main h3 {
    font-size: 16px;
  }
  .bank_page_cards ul li {
    font-size: 10px;
  }
  .profile_info_small_box_main h4 {
    font-size: 14px !important;
  }
  .profile_info_small_box_main_row .col-4 {
    margin: 0 !important;
    padding: 0 5px;
  }
  .profile_info_small_box_inner {
    width: 30px;
    height: 30px;
  }
  .profile_info_small_box_inner img {
    max-width: 20px;
  }
  .profile_headings,
  .profile_details h2,
  .profile_wallet,
  .Profile_mytext__v9ly5,
  .commaon_input_box {
    font-size: 14px !important;
  }
  .profile_wallet,
  .complete_kyc_btn {
    padding: 4px 8px;
  }
  .profile_details h3 {
    font-size: 12px;
    margin-top: 0;
  }
  .profile_details h2 {
    margin-bottom: 0;
  }
  .profile_div_main_inner .profie_img {
    max-width: 40px;
    height: 40px;
    width: 40px;
  }
  .profile_div_main_box {
    padding: 5px;
  }
  .Orher_page_main_section {
    padding: 12px;
  }
  .Orher_page_main_section .nav-tabs .nav-link {
    /* padding: 8px !important; */
    margin-right: 10px !important;
  }
  .other_pages_card_main h3 {
    font-size: 14px;
  }
  .other_pages_card_main p {
    font-size: 12px !important;
  }
  .profile_info_small_box_main_row {
    padding: 15px 5px;
  }
  .profile_div_main_box {
    margin-top: 80px;
    border-left: 0;
    border-right: 0;
  }
  .profile_history_btns a {
    font-size: 14px;
    margin-right: 5px;
    padding: 0.6rem 1rem;
  }
  .support_samll_card_btn_type {
    padding: 5px !important;
  }
  .support_samll_card_btn_type a,
  .support_samll_card_btn_type span {
    font-size: 12px !important;
  }
  .running_bet_card .Challenges_single_box_main h3 span {
    font-size: 14px;
  }
  .running_bet_card .Challenges_single_box_img h4 {
    font-size: 14px !important;
  }
  .transaction_details_modal_body .d_flex h2 {
    font-size: 12px;
  }
  .transaction_details_modal_body h1 {
    font-size: 20px;
  }
  .header_top_message {
    font-size: 10px;
  }
  .rcw-conversation-container {
    min-width: auto !important;
    max-width: inherit !important;
  }
  .game_tabs_btn{
    padding: 10px 2px  !important;
    font-size: 10px;
  }
}